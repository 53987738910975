<template>
  <v-app>
    <v-app-bar
      color="dark accent-4"
      dense
      dark
      absolute
    >
      <v-spacer></v-spacer>

      <v-btn @click="$vuetify.goTo('#fields')" text>
        What we build ?
      </v-btn>

    </v-app-bar>
    <v-container style="height: 100vh; background-color: #272727" fluid class="cover-pic align-center justify-center d-flex">
      <div class="zorx-corp__logo">
        <v-img
          class="mx-auto mb-2 logo-letter"
          :src="require('./assets/logo-no-bg.svg')"
        >
        </v-img>
        <v-img
          class="mx-auto mt-6 logo-name"
          :src="require('./assets/zorxcorp.png')"
        >
        </v-img>
      </div>
      <div class="description">
        An innovative conglomerate company
      </div>
    </v-container>


    <v-container id="fields" style="max-width: 1600px;" class="mx-auto mb-12">
      <v-card flat class="ma-12">
        <v-card-text class="black--text page-title">
          What we build ?
        </v-card-text>
      </v-card>
      <v-row class="no-gutter">

        <v-col class="col-xs-6 col-sm-12 col-md-4 col-lg-4" v-for="project in projects">
          <v-card
            class="mx-auto my-3"
            max-width="374"
          >
            <v-img
              height="250"
              :src="project.image"
            ></v-img>

            <v-card-title center>{{ project.title }}</v-card-title>

            <v-card-text>
              <div>{{ project.description }}</div>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-container>

    <v-footer
      dark
      padless
      inset
      class="mt-12"
    >
      <v-card
        flat
        tile
        style="width: -webkit-fill-available"
        class="dark accent-4 white--text text-center pt-6
        "
      >

        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon.icon"
            class="mx-4 white--text"
            icon
            :href="icon.link" target="_blank"
          >
            <v-icon size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Zorx Corp</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    projects: [
      {
        title: "Academy",
        image: require('./assets/academy.png'),
        description: 'An institute that covers a variety of tech fields targets all ages, from all levels, providing its graduates a place to work in our company.'
      },
      {
        title: "Blockchain",
        image: require('./assets/blockchain.png'),
        description: 'Projects that decentralize data and benefit from the blockchain consistency to improve different domains such as medical, IT, security, and even gaming.'
      },
      {
        title: "Consulting",
        image: require('./assets/consulting.png'),
        description: 'Reach out to our experts for help and consultation, request an expert to work for you for a specific period of your choice.'
      },
      {
        title: "Gaming",
        image: require('./assets/gaming.png'),
        description: 'From pick-up-and-play to adventures to puzzles. These innovative upstarts and our trusted experts are why Zorx Gaming continues to lap its competitors.'
      },
      {
        title: "Investment",
        image: require('./assets/investment.png'),
        description: 'We support the creators of innovative projects by providing them with financial, technological, and even administrative assistance to carry out their projects.'
      },
      {
        title: "R&D",
        image: require('./assets/research-n-developent.png'),
        description: 'An ideal environment to support the exploitation of Science and Technology in Algeria, we provide opportunities in a wide variety of R&D fields.'
      },
      {
        title: "Robotics",
        image: require('./assets/robotics.png'),
        description: 'Building the next generation of technology, with projects ranging from Planetary Exploration to Open-Source Software, AI, autonomy, and sensor fusion.'
      },
      {
        title: "Security",
        image: require('./assets/security.png'),
        description: 'A team of carefully picked, highly trained security analysts dedicated to finding and reporting zero-day vulnerabilities.'
      },
      {
        title: "Technologies",
        image: require('./assets/tech.png'),
        description: 'An IT development department specializing in the development of customized software, websites, web applications, and mobile applications.'
      }
    ],
    icons: [
      {
        "icon": "mdi-facebook",
        "link": "https://www.facebook.com/zorxCorp"
      },
      {
        "icon": "mdi-twitter",
        "link": "https://www.twitter.com/zorxCorp"
      }
    ],
  }),
};
</script>
